@import './variables';

footer {
  p {
    font-size: 1.5rem;
    color: #FFFFFF;
    font-weight: 200;
  }
  
  p.fake-link {
    color: $primary;
    cursor: pointer;
    font-size: 1rem;
  }
  
  p.fake-link:hover{
    opacity: .5;
  }
}
