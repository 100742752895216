@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

.sponsors-gallery {
  margin-top: 5rem;
}

.mid-tier {
  width: 70%;
  margin: 2rem auto;
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.bottom-tier {
  width: 70%;
  margin: 2rem auto;
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.partner-tier {
  width: 65%;
  margin: 2rem auto;
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.sponsor {
  margin: 0 auto;
  vertical-align: middle;

  &.top {
    width: 50%;
    display: block;
    margin: 0 auto 3rem;

    @include mobile {
      width: 100%;
    }
  }

  &.aws {
    width: 30%;

    @include mobile {
      width: 60%;
    }
  }

  &.mid {
    width: 50%;
    padding: 0.5rem;
    box-sizing: border-box;

    @include mobile {
      width: 50%;
    }
  }

  &.bottom {
    width: 33%;
    padding: 0.5rem;
    box-sizing: border-box;

    @include mobile {
      width: 33%;
    }
    &.vertical {
      padding-left: 4rem;
      padding-right: 4rem;
      @include mobile {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }

  &.partner {
    width: 25%;
    padding: 0.5rem;
    box-sizing: border-box;

    @include mobile {
      width: 25%;
    }

    &.vertical {
      padding-left: 2rem;
      padding-right: 2rem;
      @include mobile {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}
