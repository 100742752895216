$family-sans-serif: "Work Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$heading-font-family: 'Montserrat', sans-serif;
$heading-font-weight: 700;

$section-padding: 4rem 1.5rem;
$footer-background-color: #1A1A1A;
$footer-padding: 3rem 1.5rem;

$primary: #EF5144;
$secondary: #FEA645;
$tertiary: #462470;
$link: $primary;

$dark: #1A1A1A;

$widescreen-enabled: false;
$fullhd-enabled: false;

$modal-content-width: 100%;
